.catgris .col-md-6 {
    margin-bottom: 3%;
}

.cats-listings .col-md-4 {
    float: left;
    width: 20%;
    text-align: center;
    overflow: hidden;
}

.cats-listings .col-md-4 a {
    font-size: 17px;
    padding: 20px 0;
    border: 1px solid #393837;
    display: block;
    color: #393837;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listings .col-md-4 a:hover {
    background-color: #fdc938;
    color: #fff;
    transition: .9s;
    text-decoration: none;
    font-weight: bold;
}

.cats-listings .col-12 a {
    font-size: 15px;
    padding: 20px 0;
    border: 1px solid #393837;
    display: block;
    color: #393837;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listings .col-12 a:hover {
    background-color: #fdc938;
    color: #fff;
    transition: .9s;
    text-decoration: none;
}

.cat_name {
    position: absolute;
    left: 0;
    bottom: 50%;
    width: 100%;
    text-align: center;
    transform: translate(0%, 50%);
    padding: 10px 0;
    box-sizing: border-box;
}

.cat_name h4 {
    color: #fff;
    font-size: 45px;
    line-height: 40px;
    margin: 0;
}

.icons .col-md-2 img {
    border-radius: 50px;
}

.icons .col-4 img {
    border-radius: 50px;
    margin-bottom: 15%;
}

p {
    text-align: justify;
}

.sec-heading {
    overflow: hidden;
    border-bottom: 1px solid #000;
    color: #fff;
    background: #000;
    padding: 10px;
    display: block;
    border-radius: 5px;
    margin: 0 0 10px;
    width: 100%;
}

.left-sec li {
    list-style-type: none;
    padding: 1px 10px;
    background: #2c3434;
    margin-bottom: 0.8em;
}

.left-sec li a {
    display: block;
    font-size: 14px;
    color: #fff;
    line-height: 25px;
    text-decoration: none;
    margin: 5px 0;
    border-bottom: none !important;
    box-shadow: none;
    text-transform: capitalize;
}