.trnd a:hover {
    text-decoration: none;
}

.cats-listing1 .col-md-4 {
    float: left;
    width: 20%;
    text-align: center;
    overflow: hidden;
}

.cats-listing1 .col-md-4 a {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0;
    border: 1px solid #393837;
    display: block;
    color: #393837;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listing1 .col-md-4 a:hover {
    background-color: #fdc938;
    color: black;
    transition: .9s;
    text-decoration: none;
    font-weight: bold;
}

.cats-listing1 .col-12 a {
    font-size: 20px;
    font-weight: bold;
    padding: 8px 0;
    border: 1px solid #393837;
    display: block;
    background-color: #3A3837;
    color: white;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listing1 .col-12 a:hover {
    background-color: #fdc938;
    color: black;
    transition: .9s;
    text-decoration: none;
    font-weight: bold;
}

.box {
    color: black;
}

.box .card-body:hover {
    color: #ec4933;
    font-weight: bold;
}

.nav .nav-item .nav-link.active {
    color: red;
}

.nav li a {
    color: black;
    font-weight: bold;
}

.nav li a:hover {
    color: #ec4933;
}

#rltdtable tbody tr td a {
    color: black;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(https://www.disneyinstitute.com/assets/images/loading.420575d6.gif) 50% 50% no-repeat rgb(255, 255, 255, 0.5);
}