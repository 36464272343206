.lst li {
    font-size: 20px;
}

.cats-listing .col-md-4 {
    float: left;
    width: 20%;
    text-align: center;
    overflow: hidden;
}

.cats-listing .col-md-4 a {
    font-size: 8px;
    padding: 20px 0;
    border: 1px solid #393837;
    display: block;
    color: #393837;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listing .col-md-4 a:hover {
    background-color: #fdc938;
    color: black;
    transition: .9s;
    text-decoration: none;
    font-weight: bold;
}

.cats-listing .col-12 a {
    font-size: 17px;
    padding: 8px 0;
    border: 1px solid #393837;
    display: block;
    color: #393837;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listing .col-12 a:hover {
    background-color: #fdc938;
    color: black;
    transition: .9s;
    text-decoration: none;
    font-weight: bold;
}

/*.table {
    background-color: lavenderblush;
}*/

.table tr th {
    border: 2px solid black;
}

.table tr td {
    border: 2px solid black;
}

#shadow {
    box-shadow: 0 0 11px rgba(33, 33, 33, .1);
}

.box {
    transition: box-shadow .5s;
    box-shadow: 0 0 11px rgba(33, 33, 33, .1);
    width: 50%;
    /*height: 500px;*/
    /*margin: 50px;*/
    border-radius: 10px;
    border: 1px solid #ccc;
    background: #fff;
    /*float: left;*/
    color: black;
}

/* .box:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.5);
} */

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://www.disneyinstitute.com/assets/images/loading.420575d6.gif') 50% 50% no-repeat rgb(255, 255, 255, 0.5);
}