.trnd a:hover {
    text-decoration: none;
}

.cats-listing .col-md-4 {
    float: left;
    width: 20%;
    text-align: center;
    overflow: hidden;
}

.cats-listing .col-md-4 a {
    font-size: 8px;
    padding: 20px 0;
    border: 1px solid #393837;
    display: block;
    color: #393837;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listing .col-md-4 a:hover {
    background-color: #fdc938;
    color: black;
    transition: .9s;
    text-decoration: none;
    font-weight: bold;
}

.cats-listing .col-12 a {
    font-size: 17px;
    padding: 8px 0;
    border: 1px solid #393837;
    display: block;
    color: #393837;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listing .col-12 a:hover {
    background-color: #fdc938;
    color: black;
    transition: .9s;
    text-decoration: none;
    font-weight: bold;
}

table {
    width: 100%;
}

#main1 td:first-child {
    /*color: blueviolet;*/
    font-weight: bold;
}

#main2 th {
    /*color: blueviolet;*/
    font-weight: bold;
}

#main1,
#main2 {
    width: 100%;
    border: 1px solid black;
}

#main1 th {
    border: 1px solid black;
}

#main1 td {
    border: 1px solid black;
}

#main2 tr th h2:first-child {
    font-size: 100%;
    font-weight: bold;
}

/*#main2 tr td:last-child {
    width: 50%;
}*/

table th {
    padding: 1%;
    word-wrap: break-word;
    width: 11%;
}

table td {
    padding: 1%;
    color: black;
}

table:first tr td a {
    /*pointer-events: none;
    cursor: default;
    color: #666666;*/
    display: none;
}

#shadow {
    box-shadow: 0 0 11px rgba(33, 33, 33, .1);
}

.box {
    transition: box-shadow .5s;
    box-shadow: 0 0 11px rgba(33, 33, 33, .1);
    width: 50%;
    /*height: 500px;*/
    /*margin: 50px;*/
    border-radius: 10px;
    border: 1px solid #ccc;
    background: #fff;
    /*float: left;*/
    color: black;
}

/* .box:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.5);
} */

element.style {
    text-align: left;
    vertical-align: top;
}

.specs-mainheading {
    padding: 5px 10px 5px 10px;
}

.bottom-border-section {
    border-bottom: 1px solid black;
}

.hdngArial {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9pt;
    color: black;
    font-weight: 700;
    border: 1px solid black;
}

.bottom-border {
    border-bottom: 1px solid black;
}


.specs-value {
    padding-left: 6px;
    background-color: white;
    border-left: 1px solid black;
}

.specs {
    border-collapse: collapse;
    border: 1px solid #abc4e8;
}

.fasla {
    padding-top: 3px;
    padding-right: 3px;
    padding-bottom: 3px;
}

.RowBG1 {
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9pt;
    color: #666666;
    border: 1px solid black;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://www.disneyinstitute.com/assets/images/loading.420575d6.gif') 50% 50% no-repeat rgb(255, 255, 255, 0.5);
}