.cats-listing .col-md-2 {
    float: left;
    width: 20%;
    text-align: center;
    overflow: hidden;
}

.cats-listing .col-md-2 a {
    font-size: 20px;
    padding: 20px 0;
    border: 1px solid #393837;
    display: block;
    color: #393837;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listing .col-md-2 a:hover {
    background-color: #fdc937;
    color: #fff;
    transition: .9s;
    text-decoration: none;
    font-weight: bold;
}

.cats-listing .col-6 a {
    font-size: 20px;
    padding: 20px 0;
    border: 1px solid #393837;
    display: block;
    color: #393837;
    margin: 10px;
    border-radius: 5px;
    transition: .9s;
}

.cats-listing .col-6 a:hover {
    background-color: #fdc937;
    color: #fff;
    transition: .9s;
    text-decoration: none;
    font-weight: bold;
}