.trnd a:hover {
    text-decoration: none;
}

.box {
    color: black;
}

.box .card-body:hover {
    color: #ec4933;
    font-weight: bold;
}

.nav .nav-item .nav-link.active {
    color: red;
}

.nav li a {
    color: black;
    font-weight: bold;
}

.nav li a:hover {
    color: #ec4933;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(https://www.disneyinstitute.com/assets/images/loading.420575d6.gif) 50% 50% no-repeat rgb(255, 255, 255, 0.5);
}